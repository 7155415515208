$medium: 640px;
$medium-large:840px;
$large: 1024px;
$xlarge: 1200px;
$xxlarge: 1440px;

@mixin md {
  @media (min-width: #{$medium}) {
    @content;
  }
}

@mixin mdlg {
  @media (min-width: #{$medium-large}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$large}) {
    @content;
  }
}
// Custom breakpoint
@mixin custom($screen) {
  @media (min-width: $screen+'px') {
     @content;
  }
}


h2 {
  font-family: Lato;
  font-weight: bold;
  font-size: 37px;
  color: #777;
  line-height: 1.2;
  margin: 30px 10px 10px 30px;

    @include md {
      font-family: Lato;
      font-weight: bold;
      font-size: 77px;
      color: #777;
      line-height: 1.2;
      width: 777px;
      margin-top: 30px;
    }
}

form {
  width: 310px;
  margin: 0 0 0 30px;

  @include md {
    width: 510px;
  }
}

div.input {
    position: relative;
    padding: 0;
    margin: 0;
    padding-top: 1.5rem;
    height: 80px;
}
div.textarea {
    position: relative;
    padding: 0;
    margin: 0;
    padding-top: 1.5rem;
    height: 300px;
}

label {
    position: absolute;
    top: 0;
    font-weight: bold;
    font-size: var(--font-size-small);
    opacity: 1;
    color: rgb(74, 197, 214);
    transform: translateY(0);
    transition: all 0.3s ease-out;
}

input:placeholder-shown + label {
    opacity: 0;
    transform: translateY(1rem);
    position: relative;
}

textarea:placeholder-shown + label {
    opacity: 0;
    transform: translateY(4rem);
    position: relative;
}

textarea {
  height: 300px;
}

button.submit-btn {
  text-transform: capitalize;
  color: #fff;
  font-weight: bold;
  width: 310px;
  height: 40px;
  margin: 40px 0 70px 0;
  background-color: rgb(74, 197, 214);
  cursor: pointer;

  @include md {
    width: 510px;
  }

}

button.submit-btn:hover {
  margin: 39px 0 70px -1px;
  background-color: rgb(74, 197, 225);
  box-shadow: 1px 1px 5px 0px #ADADAD;
     -webkit-box-shadow: 1px 1px 5px 0px #ADADAD;
     -moz-box-shadow: 1px 1px 5px 0px #ADADAD;
}

video {
  width: 100%;
  height: auto;
  margin: 40px auto;
}
